import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BPMeasurement } from '../types';
import { WeightMeasurement } from './slice/WeightSlice';

export const healthApi = createApi({
    reducerPath: 'healthApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
    endpoints: (builder) => ({
        getBloodPressureMeasurements: builder.query<BPMeasurement[], void>({
            query: () => 'blood-pressure',
        }),
        addBloodPressureMeasurement: builder.mutation<BPMeasurement, Partial<BPMeasurement>>({
            query: (measurement) => ({
                url: 'blood-pressure',
                method: 'POST',
                body: measurement,
            }),
        }),
        getWeightMeasurements: builder.query<WeightMeasurement[], void>({
            query: () => 'weight',
        }),
        addWeightMeasurement: builder.mutation<WeightMeasurement, Partial<WeightMeasurement>>({
            query: (measurement) => ({
                url: 'weight',
                method: 'POST',
                body: measurement,
            }),
        }),
    }),
});

export const {
    useGetBloodPressureMeasurementsQuery,
    useAddBloodPressureMeasurementMutation,
    useGetWeightMeasurementsQuery,
    useAddWeightMeasurementMutation,
} = healthApi;