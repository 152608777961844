import * as CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_ENCRYPTION_KEY || "default-secret-key"; // Stockez ceci de manière sécurisée, pas en dur dans le code

export const encryptData = (data: string): string => {
  try {
    console.log("Encrypting data of length:", data.length, data);
    const encrypted = CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
    console.log("Encryption successful, encrypted length:", encrypted.length);
    return encrypted;
  } catch (error) {
    console.error(`Encryption failed:${error}`);
    return data;
  }
};

export const decryptData = (encryptedData: string): string => {
  try {
    console.log("Decrypting data of length:", encryptedData.length);
    const decrypted = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    console.log("Decryption successful, decrypted length:", decrypted.length);
    return decrypted;
  } catch (error) {
    console.error("Decryption failed:", error);
    // En cas d'échec de la décryption, on retourne les données telles quelles
    return encryptedData;
  }
};

export const isTokenExpired = (token: string): boolean => {
  try {
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    return decodedToken.exp * 1000 < Date.now();
  } catch (e) {
    return true;
  }
};

export const hashPassword = (password: string): string => {
  const salt =
    process.env.REACT_APP_PASSWORD_SALT ||
    "dpqsiudfhcgpsd_fadzsfsdf45s6qdf4689sd_7c89qsd7cqsd";
  return CryptoJS.SHA256(password + salt).toString();
};

export const isTokenValid = (encryptedToken: string): boolean => {
  console.log("isTokenValid: Starting token validation");
  try {
    // const token = decryptData(encryptedToken);
    const token = encryptedToken;
    console.log(
      "isTokenValid: Decrypted token:",
      token.substring(0, 10) + "..."
    );

    const parts = token.split(".");
    if (parts.length !== 3) {
      console.log("isTokenValid: Token is not in valid JWT format");
      localStorage.removeItem("authToken");
      console.log("isTokenValid: Invalid token removed from localStorage");
      return false;
    }

    const payload = JSON.parse(atob(parts[1]));
    console.log("isTokenValid: Payload parsed:", payload);

    if (!payload.exp) {
      console.log("isTokenValid: Token does not contain an expiration claim");
      localStorage.removeItem("authToken");
      console.log("isTokenValid: Invalid token removed from localStorage");
      return false;
    }

    const expirationDate = new Date(payload.exp * 1000);
    const isValid = expirationDate > new Date();
    console.log(
      "isTokenValid: Token is valid?",
      isValid,
      "Expiration:",
      expirationDate
    );

    if (!isValid) {
      localStorage.removeItem("authToken");
      console.log("isTokenValid: Expired token removed from localStorage");
    }

    return isValid;
  } catch (error) {
    console.error("isTokenValid: Error validating token:", error);
    localStorage.removeItem("authToken");
    console.log("isTokenValid: Token removed from localStorage due to error");
    return false;
  }
};
