import axios, { AxiosError } from "axios";
import { decryptData } from "./encryption";
import useApiStore from "../store/apiStore";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { baseUrl } = useApiStore.getState();
    console.log("baseUrl =>", baseUrl, process.env.REACT_APP_API_BASE_URL);
    config.baseURL = baseUrl;

    const encryptedToken = localStorage.getItem("authToken");
    if (encryptedToken) {
      const token = decryptData(encryptedToken);
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const handleAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<ApiError>;
    return axiosError.response?.data?.message || "Une erreur est survenue";
  }
  return "Une erreur inattendue est survenue";
};

interface ApiError {
  message: string;
}

export default axiosInstance;
