import React, { useMemo } from 'react';
import { BPMeasurement } from '../../types';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, CircularProgress, IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Type pour les mesures de pression artérielle avec un ID
type BPMeasurementWithId = BPMeasurement & { _id?: string };

interface BPMeasurementTableProps {
    measurements: BPMeasurementWithId[] | null | undefined;
    onDelete: (id: string) => void;
    onEdit: (id: string, data: Partial<BPMeasurementWithId>) => void;
    loading?: boolean;
    error?: string | null;
}

/**
 * Composant BPMeasurementTable
 * 
 * Ce composant affiche un tableau de mesures de pression artérielle groupées par date.
 * Il gère les états de chargement, d'erreur et d'affichage vide.
 * 
 * Flux de données :
 * 1. Les données (measurements) sont passées en props depuis le composant parent (probablement BloodPressurePage).
 * 2. Ces données proviennent généralement d'un appel API effectué dans un useEffect du composant parent.
 * 3. Les données sont stockées dans le state global (Redux) et mises à jour lorsque de nouvelles mesures sont ajoutées ou modifiées.
 * 
 * Cycle de vie :
 * - Le composant est monté lorsque la page de pression artérielle est chargée.
 * - Il se met à jour chaque fois que les props (measurements, loading, error) changent.
 * - Le composant est démonté lorsque l'utilisateur navigue vers une autre page.
 * 
 */
const BPMeasurementTable: React.FC<BPMeasurementTableProps> = ({
    measurements,
    onDelete,
    onEdit,
    loading,
    error
}) => {
    // Groupe les mesures par jour, mémorisé pour éviter des calculs inutiles
    const groupedMeasurements = useMemo(() => {
        if (!Array.isArray(measurements)) {
            console.error(`measurements is not an array:`, measurements);
            return [];
        }
        const grouped = measurements.reduce((acc, measurement) => {
            const date = new Date(measurement.timestamp).toLocaleDateString();
            if (!acc[date]) acc[date] = [];
            acc[date].push(measurement);
            return acc;
        }, {} as Record<string, BPMeasurementWithId[]>);

        return Object.entries(grouped).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime());
    }, [measurements]);

    // Gestion des états de chargement et d'erreur
    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;
    if (!measurements || measurements.length === 0) return <Typography>Aucune mesure disponible</Typography>;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Heure</TableCell>
                        <TableCell>Systolique</TableCell>
                        <TableCell>Diastolique</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groupedMeasurements.map(([date, dayMeasurements]) => (
                        <MeasurementGroup 
                            key={date} 
                            date={date} 
                            measurements={dayMeasurements}
                            onDelete={onDelete}
                            onEdit={onEdit}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

// Sous-composant pour le groupe de mesures d'une journée
const MeasurementGroup: React.FC<{
    date: string;
    measurements: BPMeasurementWithId[];
    onDelete: (id: string) => void;
    onEdit: (id: string, data: Partial<BPMeasurementWithId>) => void;
}> = React.memo(({ date, measurements, onDelete, onEdit }) => (
    <React.Fragment>
        <TableRow>
            <TableCell colSpan={5}>
                <Typography variant="subtitle1">{date}</Typography>
            </TableCell>
        </TableRow>
        {measurements.map((measurement) => (
            <MeasurementRow 
                key={measurement._id} 
                measurement={measurement}
                onDelete={onDelete}
                onEdit={onEdit}
            />
        ))}
    </React.Fragment>
));

// Sous-composant pour chaque ligne de mesure
const MeasurementRow: React.FC<{
    measurement: BPMeasurementWithId;
    onDelete: (id: string) => void;
    onEdit: (id: string, data: Partial<BPMeasurementWithId>) => void;
}> = React.memo(({ measurement, onDelete, onEdit }) => (
    <TableRow>
        <TableCell>{new Date(measurement.timestamp).toLocaleDateString()}</TableCell>
        <TableCell>{new Date(measurement.timestamp).toLocaleTimeString()}</TableCell>
        <TableCell>{measurement.systolic}</TableCell>
        <TableCell>{measurement.diastolic}</TableCell>
        <TableCell>
            <IconButton onClick={() => onEdit(measurement._id!, measurement)}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => onDelete(measurement._id!)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
    </TableRow>
));

export default BPMeasurementTable;