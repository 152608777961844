import { Google as GoogleIcon } from '@mui/icons-material';
import { Box, Button, Container, Paper, Snackbar, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import { checkAuthStatus, clearError, googleAuth, loginUser, signUpUser } from '../../store/slice/authSlice';
import { isTokenValid } from '../../utils/encryption';

// Interface pour la force du mot de passe
interface PasswordStrength {
    length: boolean;
    uppercase: boolean;
    specialChar: boolean;
}


const AuthPage: React.FC = () => {
    // États locaux pour les champs du formulaire
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [dateError, setDateError] = useState('')
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>({
        length: false,
        uppercase: false,
        specialChar: false
    });

    // Hooks Redux et React Router
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation()

    // Sélection des états depuis le store Redux
    const { error, loading, isAuthenticated } = useSelector((state: RootState) => state.auth || { error: null, loading: false });
    console.log('t1 Auth Page', isAuthenticated)

    // Effet pour mettre à jour la force du mot de passe
    useEffect(() => {
        setPasswordStrength({
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            specialChar: /[0-9!@#$%^&*]/.test(password)
        });
    }, [password]);

    // Effet pour vérifier l'authentification au chargement du composant
    useEffect(() => {
        const checkAuth = async () => {
            console.log('t2 Auth Page', isAuthenticated)
            const encryptedToken = localStorage.getItem('authToken');
            console.log('token', encryptedToken)
            // if (token && isTokenValid(token)) {
            //     console.log("ok")
            //     await dispatch(checkAuthStatus());
            // }
            if (encryptedToken) {
                console.log("Token found, validating...");
                if (isTokenValid(encryptedToken)) {
                    console.log("Token is valid, checking auth status");
                    await dispatch(checkAuthStatus());
                } else {
                    console.log("Token is invalid or expired");
                    // Optionnel : vous pouvez dispatch une action pour nettoyer l'état d'authentification
                    // dispatch(logout());
                }
            } else {
                console.log("No token found in localStorage");
            }

        };
        checkAuth();
    }, [dispatch, isAuthenticated]);

    // Effet pour rediriger l'utilisateur après une authentification réussie
    useEffect(() => {
        if (isAuthenticated) {
            const from = location.state?.from?.pathname || '/';
            navigate(from, { replace: true });
        }
    }, [isAuthenticated, navigate, location]);

    // Gestion de la soumission du formulaire
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        console.log('handleSubmit: Starting submission process');
        if (isSignUp) {
            try {
                await dispatch(signUpUser({ email, password, dateOfBirth: new Date(dateOfBirth), firstName, lastName })).unwrap()
            } catch (error) {
                console.error('Erreur d\'inscription:', error);
                if (Array.isArray(error)) {
                    error.forEach(err => {
                        if (err.includes('dateOfBirth')) {
                            setDateError('Format de date invalide');
                        }
                    });
                }
            }
        } else {
            try {
                console.log('handleSubmit: Attempting login');
                const result = await dispatch(loginUser({ email, password })).unwrap();
                console.log('handleSubmit: Login successful', result);

                // Vérifiez l'état d'authentification immédiatement après le login
                const currentState = await dispatch(checkAuthStatus()).unwrap();
                console.log('handleSubmit: Current auth state after login:', currentState);

                // Forcer une mise à jour de l'état local si nécessaire
                if (!isAuthenticated) {
                    console.log('handleSubmit: Forcing isAuthenticated update');
                    dispatch({ type: 'auth/setAuthenticated', payload: true });
                }
            } catch (error: any) {
                console.error('Erreur de connexion:', error);
                if (error === "An error occurred while validating the user") {
                    setIsSignUp(true);
                    dispatch(clearError());
                }
            }
        }
    };

    // Gestion de l'authentification Google
    const handleGoogleAuth = useCallback(() => {
        dispatch(googleAuth());
    }, [dispatch]);

    // Rendu de l'indicateur de force du mot de passe
    const renderPasswordStrength = () => (
        <Box>
            {Object.entries(passwordStrength).map(([key, value]) => (
                <Typography key={key} color={value ? 'green' : 'red'}>
                    {key === 'length' && '8 caractères minimum'}
                    {key === 'uppercase' && 'Une Majuscule'}
                    {key === 'specialChar' && 'Un chiffre ou un caractère spécial'}
                </Typography>
            ))}
        </Box>
    );

    // Gestion de la fermeture du Snackbar d'erreur
    const handleCloseSnackbar = useCallback(() => {
        dispatch(clearError());
    }, [dispatch]);

    // Rendu du composant
    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={4} style={{ padding: 20, marginTop: 50 }}>
                <Typography component="h1" variant="h5" align="center">
                    {isSignUp ? 'Inscription' : 'Connexion'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    {/* Champs de formulaire pour email et mot de passe */}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Adresse e-mail"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Mot de passe"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {renderPasswordStrength()}
                    {/* Champs supplémentaires pour l'inscription */}
                    {isSignUp && (
                        <>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="firstName"
                                label="Prénom"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="lastName"
                                label="Nom (optionnel)"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="dateOfBirth"
                                label="Date de naissance"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={dateOfBirth}
                                onChange={(e) => { setDateOfBirth(e.target.value); setDateError('') }}
                                error={!!dateError}
                                helperText={dateError}
                            />
                        </>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ margin: '24px 0 16px' }}
                        disabled={loading}
                    >
                        {isSignUp ? 'S\'inscrire' : 'Se connecter'}
                    </Button>
                </form>
                {/* Bouton pour l'authentification Google */}
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    startIcon={<GoogleIcon />}
                    onClick={handleGoogleAuth}
                    style={{ marginTop: 16 }}
                >
                    Se connecter avec Google
                </Button>
                {/* Bouton pour basculer entre connexion et inscription */}
                {!isSignUp && (
                    <Button
                        fullWidth
                        onClick={() => setIsSignUp(true)}
                        style={{ marginTop: 16 }}
                    >
                        Pas de compte ? Inscrivez-vous
                    </Button>
                )}
            </Paper>
            {/* Snackbar pour afficher les erreurs */}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!error}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={error || ''}
            />
        </Container>
    );
};

export default AuthPage;