import { create } from "zustand";

interface ApiStore {
  baseUrl: string;
  setBaseUrl: (url: string) => void;
}

const useApiStore = create<ApiStore>((set) => ({
  baseUrl:  "/api",
  setBaseUrl: (url) => set({ baseUrl: url }),
}));

export default useApiStore;
