import React, { createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react';

// Définition du type pour le contexte d'authentification
interface AuthContextType {
    isAuthenticated: boolean;
    login: (token: string) => void;
    logout: () => void;
}

// Création du contexte avec une valeur initiale undefined
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Composant AuthProvider qui gère l'état d'authentification
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    console.log('AuthProvider: Rendering');

    // État pour suivre si l'utilisateur est authentifié
    // Utilisation d'une fonction d'initialisation pour éviter des calculs inutiles à chaque rendu
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        console.log('AuthProvider: Initializing isAuthenticated state');
        const token = localStorage.getItem('authToken');
        console.log('AuthProvider: Initial token state:', token ? 'exists' : 'does not exist');
        return !!token;
    });

    // Effet pour synchroniser l'état d'authentification avec le localStorage
    useEffect(() => {
        console.log('AuthProvider: useEffect running');
        const token = localStorage.getItem('authToken');
        console.log('AuthProvider: Token from localStorage:', token ? 'exists' : 'does not exist');
        if (!!token !== isAuthenticated) {
            console.log('AuthProvider: Updating isAuthenticated state');
            setIsAuthenticated(!!token);
        } else {
            console.log('AuthProvider: isAuthenticated state is already correct');
        }
    }, []); // Dépendances vides pour ne s'exécuter qu'au montage

    // Fonction de connexion mémorisée
    const login = useCallback((token: string) => {
        console.log('AuthProvider: login called');
        localStorage.setItem('authToken', token);
        console.log('AuthProvider: Token stored in localStorage');
        setIsAuthenticated(true);
        console.log('AuthProvider: isAuthenticated set to true');
    }, []);

    // Fonction de déconnexion mémorisée
    const logout = useCallback(() => {
        console.log('AuthProvider: logout called');
        localStorage.removeItem('authToken');
        console.log('AuthProvider: Token removed from localStorage');
        setIsAuthenticated(false);
        console.log('AuthProvider: isAuthenticated set to false');
    }, []);

    // Mémoisation de la valeur du contexte pour éviter des re-rendus inutiles
    const contextValue = useMemo(() => ({
        isAuthenticated,
        login,
        logout
    }), [isAuthenticated, login, logout]);

    console.log('AuthProvider: Current isAuthenticated state:', isAuthenticated);

    // Fourniture du contexte aux composants enfants
    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook personnalisé pour utiliser le contexte d'authentification
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        console.error('useAuth: Hook used outside of AuthProvider');
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

// Version de débogage du hook useAuth avec logging détaillé
export const useAuthWithLogging = () => {
    console.log('useAuthWithLogging: Hook called');
    const context = useAuth();
    console.log('useAuthWithLogging: Returning context', { isAuthenticated: context.isAuthenticated });
    return context;
};

// Log d'initialisation du module
console.log('Auth module loaded');

// Fonction utilitaire pour logger les changements d'état (peut être utilisée dans les composants)
export const logAuthStateChange = (prevState: boolean, newState: boolean) => {
    if (prevState !== newState) {
        console.log(`Auth state changed: ${prevState} -> ${newState}`);
    }
};