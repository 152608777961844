import React, { useState, ChangeEvent } from 'react';
import { Typography, TextField, Button } from "@mui/material";
import { BPMeasurement } from '../../types';

interface MeasurementSectionProps {
    step: number;
    handleSubmit: (measurement: BPMeasurement) => void;
}

const MeasurementSection: React.FC<MeasurementSectionProps> = ({
    step,
    handleSubmit
}) => {
    const [systolic, setSystolic] = useState<string>('');
    const [diastolic, setDiastolic] = useState<string>('');

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, type: 'systolic' | 'diastolic') => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            type === 'systolic' ? setSystolic(value) : setDiastolic(value);
        }
    };

    const validateAndSubmit = () => {
        const systolicValue = parseInt(systolic);
        const diastolicValue = parseInt(diastolic);

        if (isNaN(systolicValue) || isNaN(diastolicValue)) {
            alert('Veuillez entrer des valeurs numériques valides.');
            return;
        }

        handleSubmit({
            systolic: systolicValue,
            diastolic: diastolicValue,
            timestamp: new Date()
        });

        setSystolic('');
        setDiastolic('');
    };

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Mesure {step} : Veuillez prendre votre tension artérielle.
            </Typography>
            <TextField
                label="Systolique"
                value={systolic}
                onChange={(e) => handleInputChange(e as ChangeEvent<HTMLInputElement>, 'systolic')}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Diastolique"
                value={diastolic}
                onChange={(e) => handleInputChange(e as ChangeEvent<HTMLInputElement>, 'diastolic')}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={validateAndSubmit}>
                Soumettre la Mesure
            </Button>
        </>
    );
};

export default MeasurementSection;