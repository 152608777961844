import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, Card, CardContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '../Menu';
import MeasurementSection from './MeasurementSection';
import { BPMeasurement } from '../../types';
import BPMeasurementTable from '../table/BpMeasureTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { deleteBp, fecthBpMeasurements, saveBp, updateBp } from '../../store/slice/BPSlice';
import { useAppDispatch, useAppSelector } from "../hooks/useReduxHooks"
import BulkMeasurementEntryForm from '../BulkMeasurementEntryFormProps';

// Tableau d'instructions pour la prise de tension artérielle
const instructions = [
    "Pendant les 30 minutes avant la mesure, ne faites pas d'exercice physique, ne fumez pas, évitez de manger et ne consommez pas de caféine.",
    "Installez-vous dans une pièce calme, à température confortable. Asseyez-vous et restez assis pendant 3 à 5 minutes.",
    "Asseyez-vous confortablement sur une chaise. Votre dos est soutenu par le dossier de la chaise. Vos jambes ne sont pas croisées et vos pieds sont posés à plat sur le sol.",
    "Placez votre bras nu sur une table. Le milieu du bras est au niveau du cœur.",
    "Posez le brassard sur votre bras nu, en respectant le sens indiqué sur l'appareil.",
    "Mettez l'appareil en marche : le brassard se gonfle, puis se dégonfle automatiquement. Pendant la mesure, vous ne devez ni parler, ni bouger, ni serrer le poing.",
    "L'écran du tensiomètre affiche deux valeurs. Le premier chiffre, le plus élevé, correspond à la pression artérielle systolique. Le second chiffre désigne la pression artérielle diastolique."
];

const BloodPressurePage: React.FC = () => {
    // États locaux pour gérer les différentes étapes et données du processus de mesure
    const [stage, setStage] = useState<'instructions' | 'measurement' | 'results'>('instructions');
    const [instructionStep, setInstructionStep] = useState<number>(0);
    const [measurementStep, setMeasurementStep] = useState<number>(1);
    // const [measurements, setMeasurements] = useState<BPMeasurement[]>([]);
    const [currentMeasurementSet, setCurrentMeasurementSet] = useState<BPMeasurement[]>([]);
    const [timer, setTimer] = useState<number>(180); // 3 minutes
    const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [currentMeasurement, setCurrentMeasurement] = useState<BPMeasurement | null>(null);

    const [bulkEntryOpen, setBulkEntryOpen] = useState(false);

    // Utilisation de Redux pour la gestion globale de l'état
    const dispatch = useAppDispatch()
    const { measurements, loading, error } = useSelector((state: RootState) => state.bloodPressure);
    const user = useAppSelector((state: RootState) => state.auth.user);

    // Effet pour charger les mesures au montage du composant
    useEffect(() => {
        console.log(user)
        if (user) {
            console.log('Fetching blood pressure measurements for user:', user);
            dispatch(fecthBpMeasurements({}));
        }
    }, [dispatch, user]);


    // Handle errors
    useEffect(() => {
        if (error) {
            console.error('Error in blood pressure slice:', error);
            // You might want to show an error message to the user here
        }
    }, [error]);

    // Log any changes in measurements
    useEffect(() => {
        console.log('Measurements updated:', measurements, typeof measurements);
    }, [measurements]);

    // Effet pour gérer le timer
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerRunning(false);
            if (stage === 'instructions') {
                setStage('measurement');
            }
        }
        return () => clearInterval(interval);
    }, [isTimerRunning, timer, stage]);

    // Fonction pour démarrer le processus de mesure
    const handleStart = () => {
        console.log('handleStart ok')
        setStage('instructions');
        setInstructionStep(1);
        setCurrentMeasurementSet([]);
        setIsTimerRunning(true);
        // Planifier un log pour le prochain cycle de rendu
        setTimeout(() => {
            console.log('After state update, instructionStep:', instructionStep);
        }, 0);
    };

    // Fonction pour passer à l'instruction suivante ou commencer les mesures
    const handleNextInstruction = () => {
        if (instructionStep < instructions.length - 1) {
            setInstructionStep(instructionStep + 1);
        } else {
            setStage('measurement');
            setMeasurementStep(1);
            setTimer(120); // 2 minutes between measures
            setIsTimerRunning(true);
        }
    };

    // Fonction pour soumettre une mesure
    const handleSubmit = (measurement: BPMeasurement) => {
        console.log('Submitting measurement:', measurement);
        if (isValidMeasurement(measurement)) {
            addMeasurement(measurement);
        } else {
            setCurrentMeasurement(measurement);
            setOpenDialog(true);
        }
    };

    // Fonction pour valider une mesure
    const isValidMeasurement = (measurement: BPMeasurement): boolean => {
        return measurement.systolic >= 70 && measurement.systolic <= 200 &&
            measurement.diastolic >= 40 && measurement.diastolic <= 120;
    };

    // Fonction pour ajouter une mesure à l'ensemble courant
    const addMeasurement = (measurement: BPMeasurement) => {
        console.log('Adding measurement to current set:', measurement);
        setCurrentMeasurementSet([...currentMeasurementSet, measurement]);
        if (currentMeasurementSet.length === 2) {
            setStage('results');
        } else {
            setMeasurementStep(measurementStep + 1);
            setTimer(120);
            setIsTimerRunning(true);
        }
    };


    // Fonction pour éditer une mesure (à implémenter)
    const handleEdit = (index: number) => {
        console.log('Editing measurement at index:', index);
        // Logic to edit a specific measurement
    };

    // Fonction pour sauvegarder les mesures
    const handleSave = async () => {
        console.log('Saving measurements:', currentMeasurementSet);
        if (user) {
            for (const measurement of currentMeasurementSet) {
                try {
                    await dispatch(saveBp(measurement)).unwrap();
                    console.log('Measurement saved successfully');
                } catch (error) {
                    console.error('Failed to save measurement:', error);
                }
            }

            // Récupérer seulement les mesures de la journée en cours
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Régler l'heure à minuit
            const startDate = today.toISOString().split('T')[0]; // Format YYYY-MM-DD
            // Recharger les mesures après la sauvegarde
            dispatch(fecthBpMeasurements({ startDate }));
            setStage('instructions');
            setInstructionStep(0);
            setCurrentMeasurementSet([]);
        } else {
            console.error('Cannot save measurements: User ID is undefined');
        }
    };

    // Fonction pour supprimer une mesure
    const handleDelete = async (id: string) => {
        console.log('Deleting measurement with id:', id);
        if (user) {
            try {
                await dispatch(deleteBp(id)).unwrap();
                console.log('Measurement deleted successfully');
                // Recharger les mesures après la suppression
                dispatch(fecthBpMeasurements({}));
            } catch (error) {
                console.error('Failed to delete measurement:', error);
            }
        } else {
            console.error('Cannot delete measurement: User ID is undefined');
        }
    };

    // Fonction pour générer un rapport PDF (à implémenter)
    const handleGeneratePDF = () => {
        // Logic to generate PDF report
        console.log("Generating PDF report");
    };

    const handleBulkEntryOpen = () => {
        setBulkEntryOpen(true);
    };

    const handleBulkEntryClose = () => {
        setBulkEntryOpen(false);
    };

    const handleSaveBulkMeasurements = async (bulkMeasurements: BPMeasurement[]) => {
        if (user) {
            for (const measurement of bulkMeasurements) {
                try {
                    await dispatch(saveBp({
                        ...measurement,
                    })).unwrap();
                    console.log('Bulk measurement saved successfully');
                } catch (error) {
                    console.error('Failed to save bulk measurement:', error);
                }
            }
            dispatch(fecthBpMeasurements({}));
            handleBulkEntryClose();
        } else {
            console.error('Cannot save measurements: User ID is undefined');
        }
    };

    // Fonction pour rendre les cartes de mesure
    const renderMeasurementCards = () => {
        return currentMeasurementSet.map((measurement, index) => (
            <Card key={index} style={{ marginBottom: '1rem' }}>
                <CardContent>
                    <Typography variant="h6">
                        Mesure {index + 1} - {new Date(measurement.timestamp).toLocaleTimeString()}
                    </Typography>
                    <Typography>
                        Systolique: {measurement.systolic}
                    </Typography>
                    <Typography>
                        Diastolique: {measurement.diastolic}
                    </Typography>
                    <IconButton onClick={() => handleEdit(index)}>
                        <EditIcon />
                    </IconButton>
                </CardContent>
            </Card>
        ));
    };

    // Rendu du composant
    return (
        <Container>
            <Menu />

            <Typography variant="h4" gutterBottom>
                Mesure de la Tension Artérielle
            </Typography>
            <Button variant="contained" color="primary" onClick={handleBulkEntryOpen} style={{ marginRight: '1rem' }}>
                Ajouter des mesures en masse
            </Button>

            {/* Rendu conditionnel basé sur l'étape actuelle */}
            {stage === 'instructions' && instructionStep === 0 && (
                <Button variant="contained" color="primary" onClick={handleStart}>
                    Commencer la Mesure
                </Button>
            )}
            {stage === 'instructions' && instructionStep > 0 && (
                <div>
                    <Typography variant="body1" gutterBottom>
                        {instructions[instructionStep]}
                    </Typography>
                    <Typography variant="h6">
                        Temps restant : {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={handleNextInstruction}>
                        {instructionStep < instructions.length - 1 ? "Passer à l'étape suivante" : "Commencer les mesures"}
                    </Button>
                </div>
            )}
            {stage === 'measurement' && (
                <div>
                    <MeasurementSection
                        step={measurementStep}
                        handleSubmit={handleSubmit}
                    />
                    <Typography variant="h6">
                        Temps avant la prochaine mesure : {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
                    </Typography>
                </div>
            )}
            {stage === 'results' && (
                <div>
                    <Typography variant="h5" gutterBottom>
                        Résumé des Mesures
                    </Typography>
                    {renderMeasurementCards()}
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Enregistrer les Mesures
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleGeneratePDF}>
                        Générer un rapport PDF
                    </Button>
                </div>
            )}
            <BulkMeasurementEntryForm
                open={bulkEntryOpen}
                onClose={handleBulkEntryClose}
                onSave={handleSaveBulkMeasurements}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/* <BPMeasurementDisplay /> */}
                    {/* <BPMeasurementTable measurements={measurements}  /> */}
                    <BPMeasurementTable
                        measurements={measurements}
                        onDelete={handleDelete}
                        onEdit={(id, data) => {
                            if (user) {
                                dispatch(updateBp({ id, data }));
                            }
                        }}
                        loading={loading}
                        error={error}
                    />
                </Grid>
            </Grid>
            {/* Dialogue de confirmation pour les mesures inhabituelles */}
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Confirmation de mesure inhabituelle</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        La mesure que vous avez entrée semble inhabituelle. Êtes-vous sûr de vouloir l'enregistrer ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={() => {
                        if (currentMeasurement) {
                            addMeasurement(currentMeasurement);
                            setOpenDialog(false);
                        }
                    }} color="primary" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default BloodPressurePage;
