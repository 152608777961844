import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WeightMeasurement {
    id: string;
    weight: number;
    date: string;
}

interface WeightState {
    measurements: WeightMeasurement[];
    loading: boolean;
    error: string | null;
}

const initialState: WeightState = {
    measurements: [],
    loading: false,
    error: null,
};

const weightSlice = createSlice({
    name: 'weight',
    initialState,
    reducers: {
        getWeightMeasurementsStart(state) {
            state.loading = true;
            state.error = null;
        },
        getWeightMeasurementsSuccess(state, action: PayloadAction<WeightMeasurement[]>) {
            state.measurements = action.payload;
            state.loading = false;
        },
        getWeightMeasurementsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        addWeightMeasurement(state, action: PayloadAction<WeightMeasurement>) {
            state.measurements.push(action.payload);
        },
    },
});

export const {
    getWeightMeasurementsStart,
    getWeightMeasurementsSuccess,
    getWeightMeasurementsFailure,
    addWeightMeasurement,
} = weightSlice.actions;

export default weightSlice.reducer;