import { configureStore } from "@reduxjs/toolkit";
import { healthApi } from "./HealthApi";
import bloodPressureSlice from "./slice/BPSlice";
import weightSlice from "./slice/WeightSlice";
import authSlice from "./slice/authSlice";

const store = configureStore({
  reducer: {
    bloodPressure: bloodPressureSlice,
    weight: weightSlice,
    auth: authSlice,
    
    [healthApi.reducerPath]: healthApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(healthApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
