import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuthState,
  LoginCredentials,
  SignUpCredentials,
  User,
} from "../../types/auth";
import axiosInstance, { handleAxiosError } from "../../utils/axiosConfig";
import {
  decryptData,
  encryptData,
  hashPassword,
  isTokenValid,
} from "../../utils/encryption";
import { jwtDecode } from "jwt-decode";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "/api";

console.log("Initializing auth slice");

// Helper function to handle token storage
const storeToken = (tokenData: string | { access_token: string }) => {
  console.log("Storing access_token in localStorage");
  let token: string;

  if (typeof tokenData === "string") {
    token = tokenData;
  } else if (
    tokenData &&
    typeof tokenData === "object" &&
    "access_token" in tokenData
  ) {
    token = tokenData.access_token;
  } else {
    console.error("Invalid token data:", tokenData);
    return;
  }

  if (!token) {
    console.error("Attempt to store empty or undefined token");
    return;
  }

  try {
    const encryptedToken = encryptData(token);
    localStorage.setItem("authToken", encryptedToken);
    console.log("Token stored successfully");
  } catch (error) {
    console.error("Error storing token:", error);
    localStorage.setItem("authToken", token);
    console.log("Token stored in clear text due to encryption error");
  }
};


// Helper function to retrieve and validate token
const getValidToken = (): string | null => {
  console.log("Retrieving and validating access_token from localStorage");
  const storedToken = localStorage.getItem("authToken");
  if (!storedToken) {
    console.log("No access_token found in localStorage");
    return null;
  }
  try {
    const decryptedToken = decryptData(storedToken);
    console.log("Token decrypted successfully");
    return isTokenValid(decryptedToken) ? decryptedToken : null;
  } catch (error) {
    console.error("Error decrypting token:", error);
    // En cas d'erreur de décryption, on vérifie le token stocké tel quel
    return isTokenValid(storedToken) ? storedToken : null;
  }
};

// Fonction pour décoder le token JWT
const decodeToken = (tokenData: string | { access_token: string }): User => {
  let token: string;

  if (typeof tokenData === "string") {
    token = tokenData;
  } else if (
    tokenData &&
    typeof tokenData === "object" &&
    "access_token" in tokenData
  ) {
    token = tokenData.access_token;
  } else {
    console.error("Invalid token data:", tokenData);
    return {} as User;
  }

  try {
    const decodedToken = jwtDecode<User>(token);
    console.log("Token decoded:", { ...decodedToken, _id: "[REDACTED]" });
    return decodedToken;
  } catch (error) {
    console.error("Error decoding token:", error);
    return {} as User;
  }
};

// Async thunk for checking auth status
export const checkAuthStatus = createAsyncThunk(
  "auth/checkStatus",
  async (_, { rejectWithValue }) => {
    console.log("checkAuthStatus: Starting");
    const access_token = getValidToken();
    if (!access_token) {
      console.log("checkAuthStatus: No valid access_token found");
      return rejectWithValue("Token invalide ou expiré");
    }
    try {
      const response = await axiosInstance.get(`/auth/check-status`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      if (!response.data.isAuthenticated) {
        throw new Error("L'utilisateur n'est pas authentifié");
      }

      console.log("checkAuthStatus: Server response", response.data);
      const user = decodeToken(access_token);
      return { ...response.data, user };
    } catch (error) {
      console.error("checkAuthStatus: Error", error);
      localStorage.removeItem("authToken");
      return rejectWithValue("Erreur lors de la vérification du token");
    }
  }
);

// Async thunk for user sign up
export const signUpUser = createAsyncThunk(
  "auth/signUp",
  async (userData: SignUpCredentials, { rejectWithValue }) => {
    console.log("signUpUser: Starting with data", {
      ...userData,
      password: "[REDACTED]",
    });
    try {
      const hashedPassword = hashPassword(userData.password);
      const response = await axiosInstance.post(`/auth/signUp`, {
        ...userData,
        password: hashedPassword,
      });
      console.log("signUpUser: Server response", {
        ...response.data,
        access_token: "[REDACTED]",
      });
      // const user = decodeToken(access_token)
      const access_token = response.data;
      const user = decodeToken(access_token);
      storeToken(access_token);
      return { access_token, user };
    } catch (error) {
      console.error("signUpUser: Error", error);
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

// Async thunk for user login
export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials: LoginCredentials, { rejectWithValue }) => {
    console.log("loginUser: Starting with credentials", {
      ...credentials,
      password: "[REDACTED]",
    });
    try {
      const hashedPassword = hashPassword(credentials.password);
      const response = await axiosInstance.post(`/auth/login`, {
        ...credentials,
        password: hashedPassword,
      });
      console.log("loginUser: Server response", {
        ...response.data,
        access_token: "[REDACTED]",
      });
      const access_token = response.data;
      storeToken(access_token);
      const user = decodeToken(access_token);

      return { access_token, user };
    } catch (error) {
      console.error("loginUser: Error", error);
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

// Async thunk for Google authentication
export const googleAuth = createAsyncThunk(
  "auth/googleAuth",
  async (_, { rejectWithValue }) => {
    console.log("googleAuth: Starting");
    try {
      const redirectUrl = `${API_BASE_URL}/auth/google`;
      console.log("googleAuth: Redirecting to", redirectUrl);
      window.location.href = redirectUrl;
      return null;
    } catch (error) {
      console.error("googleAuth: Error", error);
      return rejectWithValue("Erreur lors de la redirection vers Google Auth");
    }
  }
);

// Function to load initial auth state
const loadAuthState = (): AuthState => {
  console.log("loadAuthState: Starting");

  const access_token = getValidToken();
  if (access_token) {
    const user = decodeToken(access_token);
    console.log("loadAuthState: Valid access_token found");
    return {
      access_token,
      isAuthenticated: true,
      loading: false,
      error: null,
      user: user,
    };
  }
  console.log("loadAuthState: No valid access_token found");
  return {
    access_token: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    user: null,
  };
};

const initialState: AuthState = loadAuthState();
console.log("Initial auth state:", {
  ...initialState,
  access_token: initialState.access_token ? "[REDACTED]" : null,
});

// Create the auth slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      console.log("logout: Starting");
      state.access_token = null;
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("authToken");
      console.log(
        "logout: Auth state reset and access_token removed from localStorage"
      );
    },
    clearError: (state) => {
      console.log("clearError: Clearing error state");
      state.error = null;
    },
    setUser: (state, action: PayloadAction<User>) => {
      console.log("setUser: Updating user state");
      state.user = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      console.log("authSlice: Setting isAuthenticated to", action.payload);
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthStatus.pending, (state) => {
        console.log("checkAuthStatus.pending: Setting loading to true");
        state.loading = true;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        console.log("checkAuthStatus.fulfilled: Updating state with", {
          ...action.payload,
          access_token: "[REDACTED]",
        });
        // state.isAuthenticated = true;
        state.isAuthenticated = action.payload.isAutheticated;
        state.user = action.payload.user;
        state.access_token = action.payload.access_token;
        state.loading = false;
      })
      .addCase(checkAuthStatus.rejected, (state) => {
        console.log("checkAuthStatus.rejected: Resetting auth state");
        state.isAuthenticated = false;
        state.user = null;
        state.access_token = null;
        state.loading = false;
      })
      .addCase(loginUser.pending, (state) => {
        console.log(
          "loginUser.pending: Setting loading to true and clearing error"
        );
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log("loginUser.fulfilled: Updating state with", {
          ...action.payload,
          access_token: "[REDACTED]",
        });
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.access_token = action.payload.access_token;
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        console.log("loginUser.rejected: Setting error state");
        state.loading = false;
        state.error =
          (action.payload as string) ??
          action.error.message ??
          "Une erreur est survenue";
      })
      .addCase(signUpUser.pending, (state) => {
        console.log(
          "signUpUser.pending: Setting loading to true and clearing error"
        );
        state.loading = true;
        state.error = null;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        console.log("signUpUser.fulfilled: Updating state with", {
          ...action.payload,
          access_token: "[REDACTED]",
        });
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.access_token = action.payload.access_token;
        state.loading = false;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        console.log("signUpUser.rejected: Setting error state");
        state.loading = false;
        state.error =
          (action.payload as string) ??
          action.error.message ??
          "Une erreur est survenue";
      })
      .addCase(googleAuth.pending, (state) => {
        console.log(
          "googleAuth.pending: Setting loading to true and clearing error"
        );
        state.loading = true;
        state.error = null;
      })
      .addCase(googleAuth.fulfilled, (state) => {
        console.log("googleAuth.fulfilled: Setting loading to false");
        state.loading = false;
      })
      .addCase(googleAuth.rejected, (state, action) => {
        console.log("googleAuth.rejected: Setting error state");
        state.loading = false;
        state.error =
          (action.payload as string) ??
          action.error.message ??
          "Une erreur est survenue";
      });
  },
});

export const { logout, clearError, setUser, setAuthenticated } =
  authSlice.actions;
export default authSlice.reducer;

console.log("Auth slice setup complete");
