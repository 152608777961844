import { Paper, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Area, Bar, BarChart, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../store';
import { fecthBpMeasurements } from '../store/slice/BPSlice';
import { useAppDispatch, useAppSelector } from './hooks/useReduxHooks';

// Memorized selector for blood pressure state
const selectBloodPressureState = (state: RootState) => state.bloodPressure;
const bloodPressureSelector = createSelector(
  selectBloodPressureState,
  (bloodPressure) => ({
    measurements: bloodPressure.measurements,
    loading: bloodPressure.loading,
    fetched: bloodPressure.fetched
  })
);

const BloodPressureChart: React.FC = () => {
  const dispatch = useAppDispatch()
  const { measurements, loading, fetched } = useAppSelector(bloodPressureSelector);

  const bloodPressureData = useSelector((state: RootState) => state.bloodPressure.measurements);


  useEffect(() => {
    if (!fetched && !loading) {
      const today = new Date();
      const currentDay = today.getDay()

      // Calculer le premier jour de la semaine <= Lundi
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - (currentDay === 0 ? 6 : currentDay - 1));
      startDate.setHours(0, 0, 0, 0,);

      // Calculer le dernier jour de la semaine
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      endDate.setHours(23, 59, 59, 999);

      console.log('Fetch  BP for week: ', startDate, 'to', endDate)
      dispatch(fecthBpMeasurements({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }));
    }
  }, [dispatch, fetched, loading]);

  const chartData = useMemo(() => {
    console.log("MEASUREMENT CHART DATA", measurements)
    const { totalSystolic, totalDiastolic } = measurements.reduce(
      (acc, measurements) => {
        acc.totalSystolic += measurements.systolic;
        acc.totalDiastolic += measurements.diastolic;
        return acc
      }, { totalSystolic: 0, totalDiastolic: 0 })

    const avgSystolic = totalSystolic / measurements.length
    const avgDiastolic = totalDiastolic / measurements.length

    return measurements.map(m => ({
      ...m,
      avgSystolic,
      avgDiastolic,
      timestamp: new Date(m.timestamp).toLocaleString(),
    }));
  }, [measurements]);

  if (loading) {
    return (
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Tension Artérielle
        </Typography>
        <Typography>Chargement des données...</Typography>
      </Paper>
    );
  }

  if (fetched && measurements.length === 0) {
    return (
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Tension Artérielle
        </Typography>
        <Typography>Aucune donnée disponible</Typography>
      </Paper>
    );
  }
  // Vérifier si les données sont disponibles
  if (!bloodPressureData || bloodPressureData.length === 0) {
    return (
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Tension Artérielle
        </Typography>
        <Typography>Aucune donnée disponible</Typography>
      </Paper>
    );
  }

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? '' : date.toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTooltipDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleString('fr-FR');
  };

  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>Tension Artérielle</Typography>

      <Typography variant="subtitle1" gutterBottom>Évolution dans le temps</Typography>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={chartData} >
          <XAxis dataKey="timestamp" tickFormatter={formatDate} type='category' />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid stroke="#f5f5f5" />
          <Bar dataKey="diastolic" barSize={20} fill="#EBBC3D" />
          <Bar dataKey="systolic" barSize={20} fill="#413ea0" />
          <Line type="monotone" dataKey="avgDiastolic" stroke="#413ea0" />
          <Line type="monotone" dataKey="avgSystolic" stroke="#EBBC3D" />
        </ComposedChart>
      </ResponsiveContainer>

    </Paper>
  );
};
export default BloodPressureChart;