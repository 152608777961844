import { Container } from "@mui/material"
import React
    from "react"
import Menu from "../Menu"
const WeightPage: React.FC = () => { 
    return(
        <Container>
            <Menu/>
            <div>WeightPage</div>

        </Container>
    )
}

export default WeightPage