import { useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AuthPage from './components/pages/AuthPage';
import BloodPressurePage from './components/pages/BloodPressurePage';
import HomePage from './components/pages/HomePage';
import WeightPage from './components/pages/WeightPage';
import ProtectedRoute from './components/ProtectedRoute';
import { RootState } from './store';

function App() {
  // Récupère l'état d'authentification depuis le store Redux
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  console.log('t1 App', isAuthenticated)

  return (
    // Utilise BrowserRouter pour gérer le routage de l'application
    <Router>
      <Routes>
        {/* Route pour la page de connexion */}
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/" replace /> : <AuthPage />}
        />
        {/* Si l'utilisateur est authentifié, redirige vers la page d'accueil, sinon affiche la page de connexion */}

        {/* Route protégée pour la page d'accueil */}
        <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />

        {/* Route protégée pour la page de tension artérielle */}
        <Route path="/tension" element={<ProtectedRoute><BloodPressurePage /></ProtectedRoute>} />

        {/* Route protégée pour la page de poids */}
        <Route path="/poids" element={<ProtectedRoute><WeightPage /></ProtectedRoute>} />

        {/* Vous pouvez ajouter d'autres routes ici si nécessaire */}
      </Routes>
    </Router>
  );
}

export default App;