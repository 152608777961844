import React, { useEffect } from 'react';
import Menu from '../Menu';
import BloodPressureChart from '../BloodPressureChart';
import WeightChart from '../WeightChart';
import { Container, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../hooks/useReduxHooks';
import { fecthBpMeasurements } from '../../store/slice/BPSlice';

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)
  const { measurements, loading, error } = useSelector((state: RootState) => state.bloodPressure);

  useEffect(() => {
    if (user?.sub) {
      dispatch(fecthBpMeasurements({}));
    }
  }, [dispatch, user]);

  // Log any changes in measurements
  useEffect(() => {

    console.log('Measurements Depuis HomePage:', measurements, typeof measurements);
  }, [measurements]);

  return (
    <Container>
      <Menu />
      <Typography variant="h4" component="h1" gutterBottom>
        Tableau de bord santé
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <BloodPressureChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <WeightChart />
        </Grid>
      </Grid>
    </Container>

  );
};

export default HomePage;