import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BPMeasurement } from '../types';



interface BulkMeasurementEntryFormProps {
    open: boolean;
    onClose: () => void;
    onSave: (measurements: BPMeasurement[]) => void;
}

const BulkMeasurementEntryForm: React.FC<BulkMeasurementEntryFormProps> = ({ open, onClose, onSave }) => {
    const [bulkMeasurements, setBulkMeasurements] = useState<BPMeasurement[]>([]);
    const [currentMeasurement, setCurrentMeasurement] = useState<BPMeasurement>({
        systolic: 0,
        diastolic: 0,
        timestamp: new Date(),
        period: 'morning'
    });

    const handleMeasurementChange = (field: keyof BPMeasurement, value: any) => {
        setCurrentMeasurement(prev => ({ ...prev, [field]: value }));
    };

    const adjustTimestamp = (date: Date, period: 'morning' | 'evening'): Date => {
        const adjustedDate = new Date(date);
        adjustedDate.setHours(period === 'morning' ? 8 : 20, 0, 0, 0);
        return adjustedDate;
    };

    const handleAddMeasurement = () => {
        console.log('currentMeasurement', currentMeasurement)
        currentMeasurement.timestamp = adjustTimestamp(currentMeasurement.timestamp, currentMeasurement.period!)
        console.log('currentMeasurement', currentMeasurement)
        
        setBulkMeasurements(prev => [...prev, currentMeasurement]);
        setCurrentMeasurement({
            systolic: 0,
            diastolic: 0,
            timestamp: new Date(currentMeasurement.timestamp),
            period: 'morning'
        });
    };

    const handleSave = () => {
        onSave(bulkMeasurements);
        setBulkMeasurements([]);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Ajouter des mesures en masse</DialogTitle>
            <DialogContent>
                <TextField
                    label="Date de la mesure"
                    type="date"
                    value={currentMeasurement.timestamp instanceof Date
                        ? currentMeasurement.timestamp.toISOString().split('T')[0]
                        : ''}
                    onChange={(e) => handleMeasurementChange('timestamp', new Date(e.target.value))}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ marginBottom: '1rem', marginTop: '1rem' }}
                />
                <TextField
                    label="Systolique"
                    type="number"
                    value={currentMeasurement.systolic}
                    onChange={(e) => handleMeasurementChange('systolic', parseInt(e.target.value))}
                    fullWidth
                    style={{ marginBottom: '1rem', marginTop: '1rem' }}
                />
                <TextField
                    label="Diastolique"
                    type="number"
                    value={currentMeasurement.diastolic}
                    onChange={(e) => handleMeasurementChange('diastolic', parseInt(e.target.value))}
                    fullWidth
                    style={{ marginBottom: '1rem' }}
                />
                <FormControl fullWidth style={{ marginBottom: '1rem' }}>
                    <InputLabel>Période</InputLabel>
                    <Select
                        value={currentMeasurement.period}
                        onChange={(e) => handleMeasurementChange('period', e.target.value as 'morning' | 'evening')}
                    >
                        <MenuItem value="morning">Matin</MenuItem>
                        <MenuItem value="evening">Soir</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleAddMeasurement}>
                    Ajouter cette mesure
                </Button>
                <Typography variant="h6" style={{ marginTop: '1rem' }}>Mesures ajoutées:</Typography>
                {bulkMeasurements.map((measurement, index) => (
                    <Typography key={index}>
                        {measurement.timestamp?.toLocaleDateString()} -
                        {measurement.period === 'morning' ? 'Matin' : 'Soir'} -
                        Systolique: {measurement.systolic},
                        Diastolique: {measurement.diastolic}
                    </Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleSave} color="primary" autoFocus>
                    Enregistrer toutes les mesures
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BulkMeasurementEntryForm;