import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth, useAuthWithLogging } from '../contexts/AuthContext';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface ProtectedRouteProps {
    children: React.ReactElement;
}

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
//     console.log('ProtectedRoute')
//     const { isAuthenticated } = useAuthWithLogging();
//     console.log(isAuthenticated)
//     if (!isAuthenticated) {
//         return <Navigate to="/login" replace />;
//     }

//     return children;
// };

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isAuthenticated, loading } = useSelector((state: RootState) => state.auth);
    const location = useLocation();

    console.log('ProtectedRoute: Current state', { isAuthenticated, loading });

    useEffect(() => {
        console.log('ProtectedRoute: useEffect triggered', { isAuthenticated, loading });
    }, [isAuthenticated, loading]);

    if (loading) {
        console.log('ProtectedRoute: Loading');
        return <div>Chargement...</div>;
    }

    if (!isAuthenticated) {
        console.log('ProtectedRoute: Not authenticated, redirecting to login');
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    console.log('ProtectedRoute: Authenticated, rendering children');
    return <>{children}</>;
};

export default ProtectedRoute;