import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Paper } from '@mui/material';

const data = [
    { date: '2023-01-01', weight: 69.9 },
    { date: '2023-01-08', weight: 70.3 },
    { date: '2023-01-15', weight: 70.4 },
    { date: '2023-01-22', weight: 70.8 },
    { date: '2023-01-29', weight: 71.3 },
    { date: '2023-02-05', weight: 71.4 },
    { date: '2023-02-12', weight: 71.7 },
    { date: '2023-02-19', weight: 71.8 },
    { date: '2023-02-26', weight: 71.5 },
    { date: '2023-03-05', weight: 71.1 },
    { date: '2023-03-12', weight: 70.8 },
    { date: '2023-03-19', weight: 71.2 },
    { date: '2023-03-26', weight: 71.2 },
    { date: '2023-04-01', weight: 71.5 },
    { date: '2023-04-08', weight: 71.6 },
    { date: '2023-04-15', weight: 72.1 },
    { date: '2023-04-22', weight: 72 },
    { date: '2023-04-29', weight: 72 },
    { date: '2023-05-06', weight: 71.8 },
    { date: '2023-05-13', weight: 72.3 },
    { date: '2023-05-20', weight: 71.8 },
    { date: '2023-05-27', weight: 71.7 },
    { date: '2023-06-03', weight: 71.7 },
    { date: '2023-06-10', weight: 72 },
    { date: '2023-06-17', weight: 71.9 },
    { date: '2023-06-24', weight: 71.4 },
    { date: '2023-07-01', weight: 71.2 },
    { date: '2023-07-08', weight: 70.8 },
    { date: '2023-07-15', weight: 71.3 },
    { date: '2023-07-22', weight: 71.1 },
    { date: '2023-07-29', weight: 70.8 },
    { date: '2023-08-05', weight: 70.9 },
    { date: '2023-08-12', weight: 71.1 },
    { date: '2023-08-19', weight: 71.5 },
    { date: '2023-08-26', weight: 71.2 },
    { date: '2023-09-02', weight: 70.9 },
    { date: '2023-09-09', weight: 70.5 },
    { date: '2023-09-16', weight: 70.9 },
    { date: '2023-09-23', weight: 70.6 },
    { date: '2023-09-30', weight: 70.4 },
    { date: '2023-10-07', weight: 70.3 },
    { date: '2023-10-14', weight: 70 },
    { date: '2023-10-21', weight: 69.6 },
    { date: '2023-10-28', weight: 69.4 },
    { date: '2023-11-05', weight: 68.9 },
    { date: '2023-11-12', weight: 68.8 },
    { date: '2023-11-19', weight: 69.2 },
    { date: '2023-11-26', weight: 69 },
    { date: '2023-12-03', weight: 68.7 },
    { date: '2023-12-10', weight: 68.6 },
    { date: '2023-12-17', weight: 68.9 },
    { date: '2023-12-24', weight: 69.2 }
]

const WeightChart: React.FC = () => {
    return (
        <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>
                Poids
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="weight" stroke="#8884d8" name="Poids (kg)" />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export default WeightChart;