import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BloodPressureState, BPMeasurement } from '../../types';
import axiosInstance, { handleAxiosError } from '../../utils/axiosConfig';
import { RootState } from '..';
import { User } from '../../types/auth';

// Interface pour représenter une mesure avec son ID
interface BPMeasurementWithId extends BPMeasurement {
    _id: string;
}

const initialState: BloodPressureState = {
    measurements: [],
    loading: false,
    error: null,
    fetched: false
};

// Fonction helper pour obtenir l'ID de l'utilisateur depuis le state
const getUserId = (getState: () => RootState): string | null => {
    return getState().auth.user?.sub || null;
};

//Fonction helper pour obtenir l'email de l'utilisateur depuis le state
const getUser = (getState: () => RootState): User | null => {
    return getState().auth.user || null
}

export const saveBp = createAsyncThunk<BPMeasurementWithId, BPMeasurement, { state: RootState }>(
    "bp/save",
    async (measurement, { getState, rejectWithValue }) => {
        const user = getUser(getState);
        if (!user) {
            console.error('Cannot save BP: User is not authenticated');
            return rejectWithValue('User is not authenticated');
        }
        try {
            const response = await axiosInstance.post("/bp", { ...measurement, email: user.email });
            console.log('Blood pressure measurement saved:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error saving blood pressure measurement:', error);
            return rejectWithValue(handleAxiosError(error));
        }
    }
);

export const fecthBpMeasurements = createAsyncThunk<BPMeasurementWithId[], { startDate?: string; endDate?: string }, { state: RootState }>(
    'bp/getAll',
    async ({ startDate, endDate }, { getState, rejectWithValue }) => {
        const user = getUser(getState);
        console.log(user)
        if (!user) {
            console.error('Cannot fetch BP: User is not authenticated');
            return rejectWithValue('User is not authenticated');
        }
        try {
            const params = new URLSearchParams({
                email: user.email,
                ...(startDate && { startDate }),
                ...(endDate && { endDate })
            })
            const response = await axiosInstance.get(`/bp?${params.toString()}`);
            console.log('Blood pressure measurements fetched:', response.data.data);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching blood pressure measurements:', error);
            return rejectWithValue(handleAxiosError(error));
        }
    }
);

export const updateBp = createAsyncThunk<BPMeasurementWithId, { id: string; data: Partial<BPMeasurement> }, { state: RootState }>(
    'bp/update',
    async ({ id, data }, { getState, rejectWithValue }) => {
        const userId = getUserId(getState);
        if (!userId) {
            console.error('Cannot update BP: User is not authenticated');
            return rejectWithValue('User is not authenticated');
        }
        try {
            const response = await axiosInstance.put(`/bp/${id}`, { ...data, userId });
            console.log('Blood pressure measurement updated:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error updating blood pressure measurement:', error);
            return rejectWithValue(handleAxiosError(error));
        }
    }
);

export const deleteBp = createAsyncThunk<string, string, { state: RootState }>(
    'bp/delete',
    async (id, { getState, rejectWithValue }) => {
        const userId = getUserId(getState);
        if (!userId) {
            console.error('Cannot delete BP: User is not authenticated');
            return rejectWithValue('User is not authenticated');
        }
        try {
            await axiosInstance.delete(`/bp/${id}?userId=${userId}`);
            console.log('Blood pressure measurement deleted:', id);
            return id;
        } catch (error) {
            console.error('Error deleting blood pressure measurement:', error);
            return rejectWithValue(handleAxiosError(error));
        }
    }
);

const bloodPressureSlice = createSlice({
    name: 'bloodPressure',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveBp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveBp.fulfilled, (state, action) => {
                state.measurements.push(action.payload);
                state.loading = false;
            })
            .addCase(saveBp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fecthBpMeasurements.pending, (state) => {
                state.loading = true;
                state.error = null;

            })
            .addCase(fecthBpMeasurements.fulfilled, (state, action) => {
                state.measurements = action.payload;
                state.loading = false;
                state.fetched= true
            })
            .addCase(fecthBpMeasurements.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
                state.fetched= true
            })
            .addCase(updateBp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateBp.fulfilled, (state, action) => {
                const index = state.measurements.findIndex((m) => m._id === action.payload._id);
                if (index !== -1) {
                    state.measurements[index] = action.payload;
                }
                state.loading = false;
            })
            .addCase(updateBp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(deleteBp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteBp.fulfilled, (state, action) => {
                state.measurements = state.measurements.filter((m) => m._id !== action.payload);
                state.loading = false;
            })
            .addCase(deleteBp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});

export default bloodPressureSlice.reducer;